import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RouletteSector from "./sector";
import RouletteWheel from "./wheel";
import { actionNames, actions, RootState } from "../../state/reducer";
import GiftSendContainer from "./container";
import GiftCounter from "./giftCounter";
import { backend } from "../../services/backend";
import BottomLight from "./light";
import ToggleSwitch from "../../conponents/toggleSwitch";
import { defaultCardConfig } from "../../content";

const Roulette = () => {
  const State = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const gifterData = State.giftRecevier;
  const giftStage = State.giftStage;
  const giftLeftCount = State.giftsLeft;

  useEffect(() => {
    dispatch(actions.editCard(defaultCardConfig));
  }, [])

  const initSpin = () => {
    const ev = new Event("spin");
    window.dispatchEvent(ev);
  };

  const switchToLetter = () => {
    dispatch(actions.giftStage("letter"));
  };

  return (
    <div className="roulette">
      <div className="inner">
        <div className="flex columnCenter rouletteHeading">
          {giftStage === "" ? (
            <h2 style={{ color: "#FFF" }}>
              Покрути навигатор Деда Мороза
            </h2>
          ) : giftStage === "name" ? (
            <h2 style={{ color: "#FFF" }}>Твой выбор</h2>
          ) : giftStage === "letter" ? (
            <h2 style={{ color: "#FFF" }}>Твой счастливчик</h2>
          ) : null}
          <GiftSendContainer />
          {giftStage === "name" ? (
            <>
              <div className="btn letterStartBtn" onClick={switchToLetter}>
                Продолжить
              </div>
              <div className="textItallic">или</div>
            </>
          ) : null}

          {giftStage === "" || giftStage === "name" ? (
            <>
              <div
                className={`btn roulettMainBtn columnCenter${giftLeftCount === 0 ? " disabled" : ""}`}
                onClick={initSpin}
              >
                <span>
                  {giftLeftCount === 0 ? "Вы использовали все попытки. Спасибо за участие!" : giftStage === "" ? "Запустить навигатор Деда Мороза" : "Запустить ещё раз"}
                </span>
                <div className="rouletteBtnArrow" />
              </div>
              <RouletteSector />
              <RouletteWheel />
            </>
          ) : <div className={`bottomLightCtnr${giftStage === "sent" ? " sent" : " letter"}`}>
             <BottomLight noInfo={giftStage === "sent"} />
            </div>}
        </div>
      </div>
    </div>
  );
};

export default Roulette;
