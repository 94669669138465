import React, { useEffect, useState } from "react";

const CardChooseScreen = () => {
  return (
    <div className="section">
      <div className="columnCenter">
        <div className="inner cardHeading">
          <h1>Выбери шаблон</h1>
        </div>
        <div className="inner flex between cardChoose">
          <div className="cardTemplate template1">
            <a href="/cardedit/template1">
              <img src="/images/card/example/template1.svg" />
            </a>
          </div>
          <div className="cardTemplate template2">
            <a href="/cardedit/template2">
              <img src="/images/card/example/template2.svg" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardChooseScreen;
