import React from "react";
import HomepageTimer from "../timer";
import GlobalBanner from "../../conponents/banner";

const HomepageFormScreen = () => {

    return(
        <div className="section homeTimerSection">
            <div className="columnCenter">
               {/* <GlobalBanner /> */}
                <HomepageTimer heading="Подарки принимаем еще" hint="Успей подарить с 18.12.2024 до 24.12.2024" />
            </div>
        </div>
    )
}

export default HomepageFormScreen;