import React, { useEffect, useState } from "react";


const rotations = 5;

const RouletteWheel = () => {
  useEffect(() => {
    window.addEventListener("spin", () => {
      animate();
    });
  }, []);

  const [isSpinning, setIsSpinning] = useState(false);
  const [spinAngle, setSpinAngle] = useState(0);

  const animate = () => {
    // Запускаем анимацию
    setIsSpinning(true);
    let frameCounter = 0;
    /* const animInterval = setInterval(() => {
      setSpinAngle(359 * frameCounter);
      frameCounter++;
      if ( frameCounter > rotations) {
        clearInterval(animInterval)
      }
    }, 1000) */
    // Сбрасываем анимацию после завершения
    setTimeout(() => {
      setIsSpinning(false);

    }, 5000)
  };

  return (
    <div className={`rouletteWheel`}>
      <svg
        viewBox="0 0 1043 1052"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transform: `rotate(${spinAngle}deg)`
        }}
        className={`${isSpinning ? " spinning" : ""}`}
      >
        <circle
          cx="521.188"
          cy="527.164"
          r="497.426"
          stroke="white"
          stroke-opacity="0.15"
        />
        <circle cx="521.254" cy="526.164" r="481.663" fill="#181F7C" />
        <circle
          cx="523.263"
          cy="526.239"
          r="342.546"
          stroke="white"
          stroke-opacity="0.13"
        />
        <path
          d="M575.635 57.452C522.759 51.7971 480.096 56.6491 466.673 58.5063C464.416 58.8185 462.936 60.9761 463.383 63.2101L482.755 160.004C483.121 161.833 484.704 163.166 486.569 163.217L555.638 165.092C557.592 165.145 559.298 163.777 559.673 161.859L579.109 62.1709C579.555 59.8845 577.951 57.6997 575.635 57.452Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M705.398 91.7801C656.257 71.4573 613.954 64.093 600.553 62.0935C598.299 61.7573 596.271 63.4103 596.07 65.6798L587.388 164.01C587.224 165.869 588.367 167.594 590.142 168.168L655.885 189.426C657.745 190.027 659.767 189.196 660.667 187.46L707.402 97.2866C708.474 95.2184 707.551 92.6703 705.398 91.7801Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M820.233 161.276C778.808 127.932 740.294 108.948 727.999 103.254C725.931 102.296 723.519 103.311 722.687 105.432L686.654 197.333C685.973 199.07 686.583 201.047 688.125 202.098L745.216 241.017C746.831 242.118 749.006 241.89 750.358 240.478L820.604 167.124C822.216 165.442 822.048 162.737 820.233 161.276Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M910.837 260.31C880.484 216.645 848.879 187.58 838.686 178.652C836.972 177.151 834.372 177.445 832.976 179.246L772.511 257.272C771.368 258.747 771.396 260.816 772.58 262.259L816.393 315.686C817.633 317.197 819.784 317.591 821.479 316.618L909.546 266.025C911.566 264.865 912.167 262.222 910.837 260.31Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M969.871 380.858C953.049 330.411 930.912 293.618 923.647 282.18C922.426 280.257 919.848 279.807 918.001 281.141L838.003 338.972C836.491 340.066 835.935 342.059 836.664 343.776L863.651 407.383C864.414 409.182 866.367 410.166 868.268 409.71L967.021 385.978C969.286 385.434 970.607 383.068 969.871 380.858Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M992.55 513.154C990.623 460.012 979.748 418.473 976 405.452C975.37 403.262 973.023 402.104 970.876 402.864L877.824 435.814C876.066 436.437 874.971 438.193 875.186 440.047L883.16 508.679C883.386 510.621 884.982 512.116 886.935 512.213L988.374 517.265C990.7 517.381 992.635 515.482 992.55 513.154Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M977.039 646.482C990.161 594.949 991.43 552.029 991.502 538.479C991.514 536.201 989.589 534.428 987.315 534.553L888.749 539.953C886.886 540.055 885.341 541.431 885.026 543.27L873.341 611.37C873.01 613.296 874.121 615.18 875.967 615.823L971.874 649.249C974.073 650.016 976.464 648.74 977.039 646.482Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M924.593 770.038C951.703 724.29 965.012 683.466 968.899 670.485C969.552 668.303 968.205 666.059 965.987 665.538L869.893 642.95C868.077 642.523 866.206 643.409 865.386 645.084L834.988 707.133C834.128 708.889 834.663 711.009 836.253 712.146L918.858 771.238C920.752 772.594 923.406 772.043 924.593 770.038Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M839.462 873.815C878.362 837.557 902.634 802.136 910.02 790.776C911.262 788.866 910.601 786.334 908.62 785.209L822.782 736.464C821.16 735.542 819.116 735.865 817.856 737.241L771.209 788.213C769.889 789.655 769.805 791.84 771.01 793.379L833.621 873.35C835.057 875.184 837.758 875.403 839.462 873.815Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M728.542 949.403C776.082 925.573 809.349 898.426 819.637 889.607C821.367 888.124 821.446 885.508 819.862 883.87L751.234 812.916C749.937 811.575 747.885 811.309 746.289 812.275L687.171 848.039C685.498 849.051 684.802 851.124 685.524 852.94L723.068 947.311C723.93 949.476 726.46 950.447 728.542 949.403Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M600.82 990.679C653.147 981.208 692.715 964.533 705.071 958.969C707.148 958.034 707.961 955.547 706.903 953.529L661.045 866.114C660.178 864.462 658.284 863.629 656.481 864.105L589.681 881.766C587.791 882.265 586.539 884.058 586.721 886.004L596.157 987.13C596.373 989.45 598.527 991.094 600.82 990.679Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M466.642 994.3C519.518 999.955 562.181 995.103 575.604 993.246C577.86 992.934 579.341 990.776 578.894 988.542L559.522 891.749C559.156 889.919 557.573 888.586 555.708 888.535L486.639 886.661C484.685 886.608 482.978 887.975 482.604 889.894L463.168 989.581C462.722 991.868 464.325 994.053 466.642 994.3Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M336.879 959.972C386.02 980.295 428.322 987.659 441.724 989.659C443.977 989.995 446.006 988.342 446.206 986.072L454.889 887.742C455.053 885.883 453.91 884.158 452.135 883.584L386.392 862.326C384.531 861.725 382.509 862.556 381.61 864.292L334.875 954.466C333.803 956.534 334.726 959.082 336.879 959.972Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M222.044 890.476C263.469 923.821 301.983 942.804 314.278 948.499C316.346 949.456 318.758 948.442 319.589 946.321L355.623 854.419C356.304 852.682 355.694 850.705 354.152 849.654L297.061 810.735C295.446 809.634 293.271 809.862 291.919 811.274L221.672 884.628C220.061 886.311 220.229 889.016 222.044 890.476Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M131.439 791.443C161.792 835.107 193.398 864.172 203.591 873.1C205.305 874.601 207.905 874.307 209.301 872.507L269.766 794.48C270.909 793.005 270.88 790.936 269.697 789.493L225.883 736.067C224.644 734.555 222.493 734.161 220.798 735.135L132.731 785.727C130.711 786.887 130.11 789.53 131.439 791.443Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M72.406 670.895C89.2278 721.342 111.364 758.134 118.629 769.572C119.851 771.495 122.429 771.946 124.275 770.611L204.274 712.78C205.786 711.687 206.342 709.694 205.613 707.976L178.626 644.37C177.862 642.57 175.91 641.586 174.009 642.043L75.2553 665.774C72.9903 666.319 71.6691 668.685 72.406 670.895Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M49.7265 538.598C51.6543 591.741 62.5284 633.279 66.2769 646.301C66.9072 648.49 69.2536 649.649 71.4012 648.888L164.452 615.938C166.211 615.315 167.306 613.559 167.09 611.706L159.116 543.073C158.891 541.131 157.295 539.637 155.342 539.539L53.903 534.488C51.5764 534.372 49.642 536.27 49.7265 538.598Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M65.2379 405.27C52.1155 456.804 50.8465 499.723 50.7746 513.273C50.7625 515.551 52.6874 517.324 54.9623 517.199L153.527 511.799C155.39 511.697 156.935 510.321 157.251 508.482L168.936 440.383C169.267 438.456 168.156 436.572 166.31 435.929L70.4032 402.503C68.2035 401.736 65.8128 403.013 65.2379 405.27Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M117.684 281.714C90.5741 327.463 77.2646 368.286 73.3781 381.267C72.7247 383.45 74.0722 385.693 76.29 386.214L172.384 408.802C174.2 409.229 176.07 408.344 176.891 406.668L207.289 344.619C208.149 342.864 207.614 340.744 206.024 339.606L123.419 280.514C121.525 279.159 118.871 279.71 117.684 281.714Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M202.815 177.937C163.914 214.195 139.643 249.615 132.257 260.976C131.015 262.886 131.676 265.418 133.657 266.543L219.495 315.288C221.117 316.21 223.161 315.887 224.421 314.511L271.068 263.539C272.388 262.097 272.472 259.912 271.267 258.373L208.656 178.402C207.22 176.568 204.519 176.349 202.815 177.937Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M313.735 102.349C266.195 126.179 232.928 153.326 222.64 162.145C220.91 163.628 220.831 166.244 222.415 167.882L291.043 238.836C292.34 240.177 294.392 240.443 295.988 239.477L355.106 203.713C356.779 202.701 357.475 200.628 356.753 198.812L319.209 104.441C318.347 102.276 315.817 101.305 313.735 102.349Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
        <path
          d="M441.457 61.0729C389.13 70.5441 349.562 87.2191 337.206 92.7826C335.129 93.718 334.316 96.2054 335.374 98.223L381.232 185.638C382.098 187.29 383.992 188.124 385.796 187.647L452.596 169.986C454.486 169.487 455.738 167.694 455.556 165.748L446.12 64.622C445.904 62.3026 443.75 60.658 441.457 61.0729Z"
          fill="#262E9D"
          stroke="white"
          stroke-opacity="0.1"
        />
      </svg>
    </div>
  );
};

export default RouletteWheel;
