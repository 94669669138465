import React, { useEffect, useMemo, useState } from "react";
import Header from "../../conponents/header";
import CardChooseScreen from "../../conponents/screens/cardChooseScreen";

const CardChoosePage = () => {

  return (
    <div className="app profilePage columnCenter cardPage">
      <Header />
      <CardChooseScreen />
    </div>
  );
};

export default CardChoosePage;
