import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, RootState } from "../../state/reducer";

const BottomLight = (props: { noInfo: boolean }) => {
  const State = useSelector((state: RootState) => state);
  const recevier = State.giftRecevier;
  const nameText =
    recevier && recevier.first_name && recevier.last_name
      ? `${recevier.last_name[0]}${recevier.first_name[1]}`
      : "TC";
  const nameFullText =
    recevier && recevier.first_name && recevier.last_name
      ? `${recevier.last_name} ${recevier.first_name}`
      : "Тайный Санта";

  return (
    <div className="bottomLight">
      {props.noInfo ? null : (
        <div className="columnCenter">
          <div className="recevierInfo">{nameText}</div>
          <div className="recevierText">{nameFullText}</div>
        </div>
      )}
    </div>
  );
};

export default BottomLight;
