import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { profileMenuTabs } from "../../content";
import { actions, RootState } from "../../state/reducer";

const ProfileMenu = () => {
  const State = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const stage = State.giftStage; // "" | "name" | "letter" | "sent"

  const isTabActive = (index: number, url: string) => {
    switch (index) {
      case 0:
        return window.location.pathname === url; // Стандартное совпадение ссылки
      case 1:
        return (
          window.location.pathname === url &&
          stage !== "letter" &&
          stage !== "sent"
        ); // Стандартное совпадение ссылки и stage
      case 2:
        return stage === "letter"; // Активная, если stage === "letter"
      case 3:
        return stage === "sent"; // Активная, если stage === "sent"
      default:
        return false;
    }
  };
   
  return (
    <div className="profileMenu">
      <div className="profileMenuInner flex between">
        {profileMenuTabs.map((tab, index) => {
          const isActive = isTabActive(index, tab.url);

          return (
            <NavLink
              key={`pnav${index}`}
              to={tab.url}
              className={({}) =>
                isActive ? "active-profile-link" : "profile-link"
              }
              onClick={() => {
                if (tab.url === "/navigator") {
                  dispatch(actions.giftStage(""))
                }
              }}
              style={index > 1 ? {pointerEvents: "none"} : {}} // 1
            >
              {() => (
                <>
                  {isActive && (
                    <div className="menuImage">
                      <img
                        src="/images/santa.png"
                        width="72"
                        height="65"
                        alt="Icon"
                      />
                    </div>
                  )}
                  {tab.name}
                </>
              )}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileMenu;
