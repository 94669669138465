import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, RootState } from "../state/reducer";
import ProfileHeader from "../conponents/profileHeader";
import Header from "../conponents/header";
import ProfileMenu from "../conponents/menu/profileMenu";
import { CloseIcon } from "../icons/close";
import { FreeArrowIcon } from "../icons/arrowFree";
import { LockIcon } from "../icons/lock";
import ToggleSwitch from "../conponents/toggleSwitch";
import BottomMenu from "../conponents/menu/bottomMenu";
import { backend, staticUrl } from "../services/backend";
import { toast } from "sonner";
import LoadingPage from "./loading";

interface UserData {
  first_name: string;
  last_name: string;
  wish: string;
  name: string;
  photo_url?: string;
}

const defaultPhoto = "/images/default.png";

const UserProfilePage = () => {
  const [agreed, Agree] = useState(false);
  const [loading, setLoaded] = useState(true);
  const State = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [, setUserId] = useState<number | null>(null);
  const [isClientPhoto, setClientPhoto] = useState(false);
  const [userData, setUserData] = useState<UserData>({
    first_name: "",
    last_name: "",
    wish: "",
    name: "",
    photo_url: defaultPhoto,
  });

  useEffect(() => {
    backend.getUserId().then((id) => {
      console.log("Id: ", id);
      if (!id) {
        document.location.href = "/signin";
        return;
      }
      setLoaded(false);
      setUserId(id);
      backend.getUserData(String(id)).then((res: UserData) => {
        console.log(res)
        if (res) {
          setUserData(res);
          dispatch(actions.currentUser(res)); // Ensure res matches expected UserData type
        }
      });
    });
  }, []);

  const updateWishes = (event: any) => {
    const wish = event.target?.value || "";
    const newProfileData: UserData = {
      ...userData,
      wish
    }
    backend
      .updateUserProfile({
        name: newProfileData.first_name,
        surname: newProfileData.last_name,
        wish
      })
      .then(() => {
        setUserData(newProfileData);
      });
  }

  const updateInitials = (event: any) => {
    const val = event.target?.value || "",
      vals = val.split(" "),
      last_name = vals[0] || "",
      first_name = vals[1] || "";
    backend
      .updateUserProfile({
        name: first_name,
        surname: last_name,
        wish: userData.wish,
      })
      .then(() => {
        setUserData((prevData) => {
          return {
            ...prevData,
            first_name,
            last_name,
          };
        });
      });
  };

  const handlePhotoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 2097152) {
        toast("Файл не должен быть больше 2мб");
        return;
      }
      const fileURL = URL.createObjectURL(file);
      backend.savePhoto(file);
      setUserData((prevData) => {
        return {
          ...prevData,
          photo_url: fileURL,
        };
      });
      setClientPhoto(true);
    }
  };

  const handlePhotoDelete = (event) => {
    backend.deletePhoto();
    setUserData((prevData) => {
      return {
        ...prevData,
        photo_url: defaultPhoto,
      };
    });
  };

  if (loading) {
    return <LoadingPage />
  }

  return (
    <div className="app profilePage columnCenter">
      <Header />
      <div className="section screenSection">
        <ProfileHeader />
        <ProfileMenu />
        <div className="profileContent">
          <div className="inner">
            <div className="innerRow profineHeading">
              <h2>Учетная запись</h2>
            </div>
            <div className="innerRow profileInfo">
              <p className="optionHeading">ФИО</p>
              <input
                className="profileInput"
                value={`${userData.last_name} ${userData.first_name}`}
                onChange={updateInitials}
              />
            </div>
            <div className="innerRow profileInfo">
              <p className="optionHeading">Подразделение</p>
              <p className="profileInput">{userData.name}</p>
            </div>
            <div className="innerRow profileInfo photo flex">
              <div className="profileActivePhoto">
                <img
                  src={
                    (isClientPhoto
                      ? userData.photo_url
                      : `${staticUrl}${userData.photo_url}`) || defaultPhoto
                  }
                />
                <div className="btn photoCloseBtn" onClick={handlePhotoDelete}>
                  <CloseIcon width={12} height={12} />
                </div>
              </div>
              <div className="photoUploadContainer">
                <label htmlFor="photoInput" className="columnCenter">
                  <div className="fileDragDropContainer">
                    <p>Добавить фото, до 2 мб, формат: .jpg, .gif, .webp</p>
                  </div>
                  <div
                    className="btn flex uploadBtn"
                    onClick={() => document.getElementById("photo")?.click()}
                  >
                    <FreeArrowIcon width={10} height={12} />
                    <span>Выбрать фото</span>
                    <input
                      id="photoInput"
                      className="hidden"
                      type="file"
                      accept=".png,.jpg,.jpeg,.svg,.tiff,.bmp,.gif,.webp"
                      max="4mb"
                      name="photo"
                      onChange={handlePhotoUpload}
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="innerRow profileInfo">
              <p>Твои пожелания к подарку</p>
              <div className="flex between wishAreaContainer editInput">
                <textarea className="flex wishList" onChange={updateWishes} placeholder="Помоги Деду Морозу выбрать тебе подарок: расскажи что любишь, чем увлекаешься, или подскажи — у кого из коллег можно разузнать. Пиши через запятую">{userData.wish}</textarea >
              </div>
              <div className="flex between wishAreaContainer watchPreview">
                <div className="flex wishList">
                  {userData?.wish?.split(",").map((item, index) => {
                    return (
                      <div key={`wsh${index}`} className="wishItem">
                        {item}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="profileBtnFooter">
              <a href="/navigator">
                <div className="btn flex mainSubmitBtn inProfile expanded">
                     Запусти навигатор Деда Мороза
                  </div>
              </a>
            </div>
          </div>
        </div>
        <BottomMenu />
      </div>
    </div>
  );
};

export default UserProfilePage;
