import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardData, CardImage, Position } from "../../types";
import { actions, RootState } from "../../state/reducer";
import { cardImages } from "../../content";
import { addImageToCard, getImageCoords } from "./functions";

let imageHandler: (event: MouseEvent) => void;

export const CardImageEditor = (props: {
  dragStartHandler: (e: React.DragEvent<HTMLImageElement> | React.TouchEvent<HTMLImageElement>, id: string) => void;
  touchMoveHandler: any;
  touchEndHandler: any;
}) => {
  const { dragStartHandler, touchMoveHandler, touchEndHandler } = props;
  const [imageAdded, setImageAdded] = useState(false);

  const addImage = (url: string) => (event: MouseEvent) => {
    // Получаем координаты клика
    if (imageAdded) return;
    setImageAdded(true);
    const { clientX, clientY } = event;
    const position = getImageCoords(clientX, clientY);
    const image: CardImage = {
      url,
      position,
    };
    addImageToCard(image);
    document.body.style.cursor = "default";
    setTimeout(() => {
      window.removeEventListener("mousedown", imageHandler);
    }, 900);
  };

  const selectItem = (url: string) => {
    return () => {
      imageHandler = addImage(url);
      document.body.style.cursor = `url(${url}), auto`;
      setTimeout(() => {
        setImageAdded(false);
        window.addEventListener("mousedown", imageHandler);
      }, 10);
    };
  };

  return (
    <div className="flex cardEditorContainer">
      <div className="inner columnCenter">
        {cardImages.map((item, index) => {
          return (
            <div key={`editchooseimg${index}`} className="cardEditorImage">
              <img
                src={item.url}
                onDragStart={(e) => dragStartHandler(e, item.url)}
                onTouchStart={(e) => dragStartHandler(e, item.url)}
                onTouchMove={touchMoveHandler}
                onTouchEnd={touchEndHandler}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
