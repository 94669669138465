import React, { useEffect, useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowDownIcon } from "../../icons/arrowDown";
import { Star4Icon } from "../../icons/star4";
import { backend } from "../../services/backend";
import { toast } from "sonner";

const loginFormSchema = z
  .object({
    internalId: z.string().min(1, "Должно быть заполнено"),
    password: z.string().min(6, "Не менее 6 символов"),
  })
  .refine(
    async (data) => {
      const isValid = await backend.validatePassword(data);
      return !!isValid;
    },
    {
      message: "Неверный номер или пароль",
      path: ["password"],
    }
  );

const recoverFormSchema = z
  .object({
    internalId: z.string().min(1, "Должно быть заполнено"),
    groupName: z
      .string()
      .min(1, "Необходимо выбрать подразделение")
      .default(""),
  })
  .refine(
    async (data) => {
      const { internalId, groupName } = data;
      const isvalid = await backend.validateIdByGroup(internalId, groupName);
      return isvalid;
    },
    {
      message: "Табельный номер не соответствует подразделению",
      path: ["groupName"],
    }
  );

type LoginFormSchemaType = z.infer<typeof loginFormSchema>;
type RcoverSchemaType = z.infer<typeof recoverFormSchema>;

const LoginScreen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormSchemaType>({
    resolver: zodResolver(loginFormSchema),
  });

  const {
    register: recover,
    handleSubmit: handleSubmitRecover,
    formState: { errors: errorsRecover },
  } = useForm<RcoverSchemaType>({
    resolver: zodResolver(recoverFormSchema),
  });

  const [loginStage, setLoginStage] = useState<"login" | "recover">("login");
  const [pending, Pending] = useState(false);
  const [internalId, setInternalId] = useState("");
  const [code, setCode] = useState<number | null>(null);
  const [groups, setGroups] = useState<string[]>([]);

  useEffect(() => {
    backend.getAvailableGroups().then((groups) => setGroups(groups));
  }, []);

  const loginSubmit = async (data: LoginFormSchemaType) => {
    Pending(true);
    try {
      const result = await loginFormSchema.parseAsync(data);
      await backend
        .validatePassword(data)
        .then((e) => {
          document.location.href = "/profile";
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.error("Ошибка валидации:", e.errors);
    } finally {
      Pending(false);
    }
  };

  const recoverSubmit = async (data: RcoverSchemaType) => {
    if (!internalId) {
      toast("Введите табельный номер (заполните поле 1)");
      return;
    }
    try {
      const result = await recoverFormSchema.parseAsync(data);
      await backend
        .recoverPassword(data)
        .then((e) => {
          toast("Новый пароль отправлен в СМС");
          setLoginStage("login");
        })
        .catch((e) => {
          toast("Ошибка получения пароля");
          console.log(e);
        });
    } catch (e) {
      console.error("Ошибка валидации:", e.errors);
    }
  };

  return (
    <div className="section screenSection">
      <div className="inner flex columnCenter">
        <form
          className="loginForm"
          onSubmit={
            loginStage === "login"
              ? handleSubmit(loginSubmit)
              : handleSubmitRecover(recoverSubmit)
          }
        >
          <div className="inner columnCenter">
            <div className="loginFromRow loginHeading">
              {(loginStage === "login") ? <h3>Вход</h3> : <h3>Восстановление пароля</h3>}
            </div>
            {(loginStage === "login") ?<div className="loginFromRow">
              <label>Табельный номер</label>
              <div className="inputFullWidth expanded">
                <input
                  className="enterId"
                  type="text"
                  {...register("internalId")}
                  onChange={(event) => setInternalId(event.target.value)}
                />
              </div>
              <label>Твой  пароль от клуба анонимных Дедов Морозов</label>
              <div className="inputFullWidth expanded">
                <input
                  key={"enId"}
                  className="enterId"
                  type="password"
                  {...register("password")}
                />
                {errors.password ? (
                  <p className="errorInputText">{errors.password?.message}</p>
                ) : null}
              </div>
            </div> : <div className="loginFromRow">
              <label>Табельный номер</label>
              <div className="inputFullWidth expanded">
                <input
                  key={"inId"}
                  className="enterId"
                  type="text"
                  {...recover("internalId")}
                  onChange={(event) => setInternalId(event.target.value)}
                />
              </div>
              <label>Подразделение</label>
              <div className="inputFullWidth expanded">
                <select
                  className="enterId"
                  {...recover("groupName")}
                >
                  <option value="">Выберите подразделение</option>
                  {groups.map((item, index) => {
                      return (
                        <option key={`lgfgrp${index}`} value={item}>
                          {item}
                        </option>
                      );
                    })}
                </select>
                {errorsRecover.groupName ? (
                  <p className="errorInputText">{errorsRecover.groupName?.message}</p>
                ) : null}
              </div>
            </div>}
            <div className="loginFromRow">
              <button
                className="btn flex mainSubmitBtn expanded"
                disabled={pending}
                type="submit"
              >
                <Star4Icon width={27} height={27} />
                <div className="submitText" style={{ fontSize: 20 }}>{loginStage === "login" ? "Войти" : "Восстановить пароль"}</div>
              </button>
            </div>
            {loginStage === "login" ? (
              <div
                className="svgBtn textBtn"
                onClick={() => setLoginStage("recover")}
              >
                Запросить новый пароль
              </div>
            ) : null}
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
