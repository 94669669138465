import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, RootState } from "../../state/reducer";
import { GiftIcon } from "../../icons/gift";
import { userPhotoUrl } from "../../content/utils";
import { backend } from "../../services/backend";
import GiftNameInfo from "./nameInfo";
import ToggleSwitch from "../toggleSwitch";
import { Card, CardImageEditor } from "../card";
import { defaultCardConfig } from "../../content";

const GiftSendContainer = () => {
  const State = useSelector((state: RootState) => state);
  const [letter, setLetter] = useState("");
  const [deanon, Deanon] = useState(false);
  const [displayed, displayInstruction] = useState(false);
  const [pending, Pending] = useState(false);
  const dispatch = useDispatch();
  const gifterData = State.giftRecevier;
  const card = State.editCard;
  const giftStage = State.giftStage;
  const estimateGift = State.giftsLeft;

  const sendGift = () => {
    if (!State.giftRecevier?.id) return;
    Pending(true);
    backend
      .makeGift({
        to: State.giftRecevier?.id,
        message: letter,
        deanon,
        card: { ...State.editCard },
      })
      .then((res) => {
        dispatch(actions.giftStage("sent"));
        Pending(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    Pending(false);
  }, [giftStage])

  const inner = (() => {
    switch (giftStage) {
      case "name":
        return <GiftNameInfo />;
      case "letter":
        return (
          <>
            <GiftNameInfo />
            {giftStage === "letter" ? (
              <div
                className="checkboxContainer"
                style={{
                  /* visibility: "hidden", */
                }}
              >
                <div className="flex checkboxRow letterCheckbox">
                  <ToggleSwitch
                    checked={deanon}
                    onChange={(checked) => Deanon(checked)}
                  />
                  <span>Показать мое имя получателю</span>
                </div>
              </div>
            ) : null}
            <Card
              card={defaultCardConfig}
              editable={true}
              onEdit={(value: string) => setLetter(value)}
            />
            <div className="instructionText interactive">
              <a onClick={() => displayInstruction(true)}>
                Инструкция отправки и вручения подарка
              </a>
            </div>
            {displayed ? (
              <div className="instructionText expanded">
                <p>Положи подарки в мешок Деда Мороза с 18 по 24 декабря</p>
                <p>Получи подарки от Деда Мороза с 25 по 28 декабря</p>
                <p>
                  Упакуй подарки и используй специальный стикер, чтобы подписать
                  их
                </p>
              </div>
            ) : null}
            <div
              className="btn roulettMainBtn columnCenter sendGiftBtn"
              onClick={sendGift}
              style={pending ? { pointerEvents: "none" } : {}}
            >
              <span>Сделать подарок ✔</span>
              <div className="rouletteBtnArrow" />
            </div>
          </>
        );
      case "sent":
        return (
          <div className="columnCenter">
            <div style={{ height: 100 }} />
            <GiftIcon width={48} height={43} />
            <div className="flex columnCenter rouletteHeading">
              <p style={{ color: "#FFF", width: "70%", textAlign: "center" }}>
                Ты можешь отправить подарок снова перейдя на раздел Колесо Удачи
              </p>
            </div>
            <div className="giftTimer columnCenter" style={{ marginTop: 40 }}>
              <div
                className="timer flex columnCenter"
                style={{ marginTop: 40, marginBottom: 120 }}
              >
                <div
                  className={`btn roulettMainBtn columnCenter sendGiftBtn${estimateGift > 0 ? "" : " disabled"}`}
                  onClick={() => dispatch(actions.giftStage(""))}
                  style={estimateGift > 0 ?{} : { pointerEvents: "none" }}
                >
                  {estimateGift > 0 ? "Запустить навигатор ещё раз" : "Вы использовали все попытки. Спасибо за участие!"}
                </div>
              </div>
              <div className="flex columnCenter rouletteHeading">
                <p style={{ color: "#FFF" }}>
                  Подарки принимаем с 18 по 24 декабря
                </p>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  })();

  return <div className="giftSendContainer columnCenter">{inner}</div>;
};

export default GiftSendContainer;
