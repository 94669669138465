import React, { useEffect, useState } from "react";
import Header from "../conponents/header";
import EntrieScreen from "../conponents/screens/entrieScreen";
import HomepageFormScreen from "../conponents/screens/formScreen";
import MainPageForm from "../conponents/mainPageForm";
import BottomMenu from "../conponents/menu/bottomMenu";
import LoadingPage from "./loading";

const HomePage = () => {
  const [loading, setLoading] = useState(true);
  const [imageBg, setImageBg] = useState("");

  const preloadData = () => {
    setImageBg
      (window.innerWidth > 641
        ? "/images/hochland_dm-2k.png"
        : "/images/background_mobile.png");
    const img = new Image();
    img.onload = () => setLoading(false);
    img.onerror = () => {
      // Можно обработать ошибку загрузки, например, использовать запасное изображение или продолжить без него
      setLoading(false);
    };
    img.src = imageBg;
  };

  const handleResize = () => {
    // Вы можете обновить логику предзагрузки при изменении размера экрана
    setLoading(true); // Начать загрузку заново при изменении размера
    preloadData();
  };

  useEffect(() => {
    preloadData();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /* if (loading) {
    return <LoadingPage />;
  } */

  return (
    <div className="app mainPage columnCenter" style={{ backgroundImage: `url('${imageBg}')` }}>
      <Header />
      <EntrieScreen />
      <HomepageFormScreen />
      <MainPageForm />
      <BottomMenu />
    </div>
  );
};

export default HomePage;
