import { backend } from "../../services/backend";
import { GiftIcon } from "../../icons/gift";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, RootState } from "../../state/reducer";

const giftLimit = 3;

const GrftCounter = () => {
  const State = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const displayCount = State.giftsLeft;

  useEffect(() => {
    backend
      .getUserGiftCount()
      .then((count) => {
        const elapsed = count > giftLimit ? 0 : giftLimit - count;
        dispatch(actions.giftsLeft(isNaN(elapsed) ? 0 : elapsed));
      })
      .catch((e) => {
        dispatch(actions.giftsLeft(giftLimit));
      });
  }, [State.giftStage, State.giftRecevier]);

  const giftText: string = (() => {
    switch (displayCount) {
      case 0:
        return "подавков";
      case 1:
        return "подарок";
      default:
        return "подарка";
    }
  })();

  return (
    <div className="giftCounter">
      <div className="text textElapsed">Осталось:</div>
      <div className="flex">
        <div className="countNum">{displayCount}</div>
        <GiftIcon width={48} height={43} />
      </div>
      <div className="sub">{giftText}</div>
    </div>
  );
};

export default GrftCounter;
