import { FreeArrowIcon } from "../../icons/arrowFree";
import { fileEvents } from "../../content";
import { FileIcon } from "../../icons/file";
import React, { useEffect, useState } from "react";

const EditCardActionMenu = (props: {
  editable: boolean;
  onFileStart: () => void;
}) => {
  const [expanded, setExpanded] = useState(false);
  const initSave = (eventname: string) => () => {
    const ev = new Event(eventname);
    props.onFileStart();
    setTimeout(() => {
      window.dispatchEvent(ev);
    }, 10);
  };

  return (
    <div className={`cardActionMenu${expanded ? " expanded" : ""}`}>
      <div className="inner flex between">
        <div className="flex">
          <div className="flex">
            <div className="svgBtn fileBtn" onClick={initSave(fileEvents.png)}>
              <FileIcon width={15} height={17} />
              <span>Скачать .png</span>
            </div>
            <div className="svgBtn fileBtn" onClick={initSave(fileEvents.pdf)}>
              <FileIcon width={15} height={17} />
              <span>Скачать .pdf</span>
            </div>
          </div>
        </div>
        <div className="btn mainSubmitBtn">
            <a href="/cardedit">Назад</a>
          </div>
      </div>
      <div
        className="cardExpandBtn mobile"
        onClick={() => setExpanded((state) => !state)}
      >
        <FreeArrowIcon width={16} height={16} />
      </div>
    </div>
  );
};

export default EditCardActionMenu;
