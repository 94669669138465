import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, RootState } from "../state/reducer";
import ProfileHeader from "../conponents/profileHeader";
import Header from "../conponents/header";
import ProfileMenu from "../conponents/menu/profileMenu";
import BottomMenu from "../conponents/menu/bottomMenu";
import Roulette from "../conponents/roulette";
import GiftCounter from "../conponents/roulette/giftCounter";
import { RouletteStages } from "../types";

const RoulettePage = (props: { stage: RouletteStages} = { stage: "" }) => {

  const State = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.stage) {
       dispatch(actions.giftStage(props.stage))
    }
  }, [])

  return (
    <div className="app profilePage columnCenter">
      <Header />
      {/* <div className="profileOverlay background2" /> */}
      <div className="section screenSection">
        <ProfileHeader />
        <ProfileMenu />
        <Roulette />
        <GiftCounter />
      </div>
      <div className="wmBg" style={{ backgroundImage: "url('/images/profile_bg_roulette.png')"}} />
      <div className="wmHomeBg" />
      {State.giftStage === "" ? <img src="/images/santa_2.png" className="pc" style={{
        position: "absolute",
        bottom: 0,
        left: 200,
        width: 356
      }} />: null}
      {State.giftStage === "name" ? <img src="/images/snegovik.png" className="pc" style={{
        position: "absolute",
        bottom: 0,
        right: 200,
        width: 184
      }} />: null}
    </div>
  );
};

export default RoulettePage;
