import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardData, CardImage, Position } from "../../types";
import { actions, RootState } from "../../state/reducer";
import { CardImageFC } from "./image";
import { CardImageEditor } from "./imageEditor";
import { addImageToCard } from "./functions";
import { cardThemes, fileEvents } from "../../content";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const Card = (props: {
  card: CardData;
  editable?: boolean;
  theme?: string;
  onEdit?: (value?: any) => void;
}) => {
  const { card, editable, onEdit } = props;
  const State = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [coordinates, setCoordinates] = useState<Position[]>([]);
  const [editedCard, setEditedCard] = useState<CardData>(card);
  const [idFileCreating, setFileCreating] = useState(false);

  const handleSavePng = async () => {
    console.log("png")
    const element = document.getElementById("makingCard");
    if (!element) return;
    const canvas = await html2canvas(element, {
      scale: 2, // Повышение качества
      useCORS: true, // Разрешение кросс-доменного рендеринга изображений
      backgroundColor: null, // Прозрачный фон
    });
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = "card.png";
    link.click();
  }

  const handleSavePdf = async () => {
    const element = document.getElementById("makingCard");
    if (!element) return;
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("portrait", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("card.pdf");
  }

  useEffect(() => {
    if (editable) dispatch(actions.editCard(card));
    if (!editable) {
      window.addEventListener(fileEvents.png, handleSavePng);
      window.addEventListener(fileEvents.pdf, handleSavePdf);
    }
    return () => {
      window.removeEventListener(fileEvents.png, handleSavePng);
      window.removeEventListener(fileEvents.pdf, handleSavePdf);
    };
  }, [editable]);

  const editMessage = (event: any) => {
    const value = event.target?.value || "";
    const newCard = { ...State.editCard, message: value };
    dispatch(actions.editCard(newCard));
    setEditedCard(newCard); // Обновляем только локальное состояние
    if (onEdit) onEdit(value);
  };

  const editAddMessage = (event: any) => {
    const value = event.target?.value || "";
    const newCard = { ...State.editCard, message_add: value };
    dispatch(actions.editCard(newCard));
    setEditedCard(newCard); // Обновляем только локальное состояние
    if (onEdit) onEdit(value);
  };

  const editTitle = (event: any) => {
    const value = event.target?.value || "";
    const newCard = { ...State.editCard, title: value };
    dispatch(actions.editCard(newCard));
    setEditedCard(newCard); // Обновляем только локальное состояние
    if (onEdit) onEdit(value);
  };

  const handleDragStart = (
    e: React.DragEvent<HTMLImageElement> | React.TouchEvent<HTMLImageElement>,
    id: string
  ) => {
    console.log(`Called for:`, id)
    if (!editable) {
      e.preventDefault();
      return;
    }
  
    if (e.type === 'touchstart') {
      // Обработка touch-события
      const touchEvent = e as React.TouchEvent<HTMLImageElement>;
      const touch = touchEvent.touches[0];
      // Ваш код для обработки touch-события
    } else if (e.type === 'dragstart') {
      // Обработка drag-события
      const dragEvent = e as React.DragEvent<HTMLImageElement>;
      dragEvent.dataTransfer.setData("imageId", id);
    }
  };
  
  const handleTouchMove = (e: React.TouchEvent<HTMLImageElement>) => {
    // console.log("Moving:", e.target)
    const draggingElement = document.querySelector('[data-dragging="true"]');
    if (draggingElement) {
      // Обработка перетаскивания
      console.log(`Dragging element with id: ${draggingElement.getAttribute("data-id")}`);
    }
  };
  
  const handleTouchEnd = (e: React.TouchEvent<HTMLImageElement>) => {
    const draggingElement = document.querySelector('[data-dragging="true"]') as HTMLElement;
    const image = e.target as HTMLImageElement;
    const src = image.src;
    console.log("Finished for:", image);
    const touch = e.changedTouches[0];
    const position = { x: touch.clientX, y: touch.clientY };
    console.log("Touch position:", position);
    onDropImage(src, position);
  };

  const onDropImage = (url: string, position: Position) => {
    const precessed: Position = { x: position.x - 200, y: position.y - 80 };
    const image: CardImage = { url, position: precessed };
    addImageToCard(image);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const imageId = e.dataTransfer.getData("imageId");
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Запоминаем координаты
    const position = { x, y, imageId };
    setCoordinates((prevCoordinates) => [...prevCoordinates, position]);

    // Передаем координаты и ID изображения в родительский компонент
    onDropImage(imageId, position);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault(); // Для разрешения перетаскивания
  };

  console.log("Theme:", props.theme);

  return (
    <div id="makingCard" style={
      props.theme
        ? {
            backgroundImage: "url('/images/card/bg/custom.svg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            padding: 20
          }
        : { backgroundImage: "url('/images/letter_bkg.svg')", backgroundRepeat: "no-repeat" }
    }>
      <div
        id="imageCard"
        className={`${!props.theme ? "giftWindow sendStage" : ""}`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div className="inner columnCenter" style={{ position: "relative" }}>
          <div style={{ height: 85 }} />
          {State.editCard.images.map((image, index) => {
            return (
              <CardImageFC
                key={`incardimg${index}`}
                image={image}
                editable={editable}
              />
            );
          })}
          {editable ? (
            <input
              className="cardHeaderEdit"
              style={{ marginBottom: 20 }}
              value={State.editCard.title}
              onChange={editTitle}
            />
          ) : (
            <h2 className="cardHeader">{State.editCard.title}</h2>
          )}
          {editable ? (
            <div
              className="flex"
              style={{
                width: "100%",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <textarea
                className="letterText"
                rows={6}
                style={{
                  width: "100%"
                }}
                placeholder={
                  State.editCard.gift_id
                    ? "Поздравь своего счастливчика"
                    : "Напиши поздравления друзьям или коллегам"
                }
                onChange={editMessage}
                maxLength={200}
              >
                {State.editCard.message}
              </textarea>
              <div className="wishLimitHint">
                {`${State.editCard.message?.length || "0"} / 200`}
              </div>
            </div>
          ) : (
            <>
              <div className="letterText">{State.editCard.message}</div>
              {props.theme === cardThemes.template2 ? (
                <div
                  style={{
                    width: "100%",
                    height: 90,
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <img
                    src="/images/card/7.svg"
                    style={{ width: 124, height: 52, marginTop: 30 }}
                  />
                </div>
              ) : null}
              {props.theme ? (
                <div className="letterText" style={{ marginTop: 20 }}>
                  {State.editCard.message_add}
                </div>
              ) : null}
            </>
          )}
          {!props.theme ? (
            <>
              <img
                src="/images/logo.svg"
                style={{ marginTop: !props.theme ? 70 : 140, marginLeft: 20 }}
              />
            </>
          ) : null}
          <p
            className="regardsText"
            style={
              props.theme === cardThemes.template2 ? { marginTop: 80 } : {}
            }
          >
            С уважением, Дед Мороз
          </p>
        </div>
      </div>
      {editable ? <CardImageEditor   
      dragStartHandler={handleDragStart}
      touchMoveHandler={handleTouchMove}
      touchEndHandler={handleTouchEnd} /> : null}
    </div>
  );
};
