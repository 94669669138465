import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, RootState } from "../../state/reducer";
import { GiftIcon } from "../../icons/gift";
import { userPhotoUrl } from "../../content/utils";

const GiftNameInfo = () => {
  const State = useSelector((state: RootState) => state);
  const [letter, setLetter] = useState("");
  const [pending, Pending] = useState(false);
  const dispatch = useDispatch();
  const gifterData = State.giftRecevier;
  const giftStage = State.giftStage;
  const currentUser = State.currentUser;

  const imageFromText =
    currentUser && currentUser.first_name && currentUser.last_name
      ? `${currentUser.first_name[0]}${currentUser.last_name[0]}`
      : "ТС";

  const imageToText =
    gifterData && gifterData.first_name && gifterData.last_name
      ? `${gifterData.first_name[0]}${gifterData.last_name[0]}`
      : "ТС";

  return (
    <div
      className={`giftWindow nameStage${
        giftStage === "letter" ? " letterStage" : ""
      }`}
    >
      <GiftIcon width={48} height={43} classNames={["gift"]} />
      <div className="inner columnCenter">
        <div className="flex imageBinContainer">
          <div className="imageFrom">{imageFromText}</div>
          <div className="imageTo">{imageToText}</div>
        </div>
        <div className="descriptionCtnr">
          <div className="descHead">Подразделение:</div>
          <div className="descText">{gifterData?.department || ""}</div>
        </div>
        <div className="descriptionCtnr">
          <div className="descHead">ФИО:</div>
          <div className="descText">{`${gifterData?.last_name || ""} ${
            gifterData?.first_name || ""
          }`}</div>
        </div>
        {giftStage === "letter" ? (
          <>
            <div className="descriptionCtnr wishCtnr">
              <div className="descText">Пожелания:</div>
            </div>
            <div className="descriptionCtnr wishCtnr">
              {gifterData?.wish?.split(",").map((item, index) => {
                return (
                  <div key={`letterwish${index}`} className="descHead">
                    {item}
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default GiftNameInfo;
